import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ISensor } from "src/types";

export function useSensors(orgSlug?: string) {
  const getData = useCallback(async () => {
    const res = await axios.get<ISensor[]>(`/${orgSlug}/lookups/sensortypes`);
    return res?.data;
  }, [orgSlug]);

  return useQuery({
    queryKey: ["organization", orgSlug, "sensors"],
    queryFn: getData,
    enabled: !!orgSlug,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
