import { useCallback, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Download, Info } from "react-feather";
import { downloadFile } from "src/utils";
import {
  useImageUploadActions,
  useImageUploadState,
} from "src/components/useImageUploadStore";
import EnterpriseOrgSensor from "./EnterpriseOrgSensor";
import { useUser } from "src/api/useUser";
import { usePostCollectEvent } from "src/api/usePostCollectEvent";
import Autocomplete from "src/lib/Select/Autocomplete";
import DatePicker from "src/lib/DatePicker/DatePicker";
import { useCollectEvents } from "src/api/useCollectEvents";
import { ICollectEvent } from "src/types/ICollectEvent";
import { useOrthoUploader } from "src/api/useOrthoUploader";
import RequestTable from "./RequestTable";
import { RequestData } from "src/types/RequestData";
import { useTranslation } from "react-i18next";

export function OrthoUploader() {
  const userQ = useUser();
  const actions = useImageUploadActions();
  const {
    endDate,
    startDate,
    organization,
    formData,
    enterprise,
    overwriteCollectEvent,
    sensorType,
  } = useImageUploadState();
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [overwrite, setOverwrite] = useState(false);
  const requestRefs = useRef<RequestData[]>([]);
  const [requests, setRequests] = useState<RequestData[]>([]);
  const collectEventMutation = usePostCollectEvent(organization?.slug);
  const exportJsonCollectEventMutation = usePostCollectEvent(
    organization?.slug
  );
  const uploadMutation = useOrthoUploader({
    onSettled: (response, err, variables) => {
      const existing = requestRefs.current.find((f) => f.id === variables.id);
      if (existing) {
        existing.isError = !!err;
        existing.statusMessage = err ?? "Success";
        existing.response = response;
      }
      setRequests([...requestRefs.current]);
    },
  });
  const collectEventsQ = useCollectEvents(
    userQ.data?.isOrgUser
      ? userQ.data?.metadata?.tenant.org.id
      : organization?.slug ?? organization?.id,
    sensorType?.id
  );

  const alwaysReqd =
    formData?.tenantIdOrSlug &&
    formData?.organizationSlug &&
    formData?.sensorTypeId &&
    file;
  const submitEnabled = overwrite
    ? overwriteCollectEvent && alwaysReqd
    : alwaysReqd;

  const postToTiler = useCallback(
    (cid: string) => {
      if (cid && enterprise && organization && file) {
        const id = requestRefs.current.length.toString();
        const body = {
          id,
          statusMessage: "In progress",
          startedOn: new Date(),
          collectEventId: cid,
          collectEventName:
            overwriteCollectEvent?.name || formData?.collectEventName,
          file,
          method: overwrite ? "put" : ("post" as "put" | "post"),
          enterprise,
          organization,
        };
        const reqs = [...requestRefs.current, body];
        requestRefs.current = reqs;
        setRequests(reqs);
        actions.setCollectEventName("");
        if (fileRef.current) fileRef.current.value = "";
        setFile(null);
        uploadMutation.mutate(body);
      }
    },
    [
      actions,
      enterprise,
      file,
      formData?.collectEventName,
      organization,
      overwrite,
      overwriteCollectEvent?.name,
      uploadMutation,
    ]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (file && enterprise && organization) {
        const collectEventData = {
          name:
            overwrite && overwriteCollectEvent
              ? overwriteCollectEvent.name
              : formData?.collectEventName ?? "",
          sensorTypeId: formData?.sensorTypeId,
          beginOnUtc:
            overwrite && overwriteCollectEvent
              ? overwriteCollectEvent?.beginOnUtc
              : formData?.beginOnUtc ?? "",
          endOnUtc:
            overwrite && overwriteCollectEvent
              ? overwriteCollectEvent?.endOnUtc
              : formData?.endOnUtc ?? "",
        };
        const id = requestRefs.current.length.toString();
        if (overwrite && overwriteCollectEvent) {
          postToTiler(overwriteCollectEvent.id);
        } else {
          const res = await collectEventMutation.mutateAsync(collectEventData, {
            onError: (e) => {
              const body = {
                id,
                statusMessage: e,
                isError: true,
                startedOn: new Date(),
                collectEventId: "",
                collectEventName:
                  overwriteCollectEvent?.name || formData?.collectEventName,
                file,
                method: overwrite ? "put" : ("post" as "put" | "post"),
                enterprise,
                organization,
              } as RequestData;
              const reqs = [...requestRefs.current, body];
              requestRefs.current = reqs;
              setRequests(reqs);
            },
          });
          postToTiler(res.id);
        }
      }
    },
    [
      collectEventMutation,
      enterprise,
      file,
      formData?.beginOnUtc,
      formData?.collectEventName,
      formData?.endOnUtc,
      formData?.sensorTypeId,
      organization,
      overwrite,
      overwriteCollectEvent,
      postToTiler,
    ]
  );

  return (
    <>
      <Typography textTransform={"uppercase"} variant="h5" component="h2">
        Ortho Uploader
      </Typography>
      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography>
          Upload a fully processed orthomosaic .tif image less than 200MB
        </Typography>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <form onSubmit={handleSubmit}>
          <EnterpriseOrgSensor />
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>{t("common.create")}</Typography>
            <Switch
              checked={overwrite}
              onChange={(e) => {
                setOverwrite(e.target.checked);
                if (!e.target.checked) {
                  actions.setOverwriteCollectEvent(undefined);
                }
              }}
              inputProps={{ "aria-label": "Overwrite switch" }}
            />
            <Typography>{t("common.overwrite")}</Typography>
          </Stack>
          {overwrite ? (
            <Autocomplete
              label="Overwrite collection event"
              options={collectEventsQ.data}
              value={overwriteCollectEvent ?? null}
              onChange={(_e, opt) => {
                const item = opt as ICollectEvent;
                actions.setOverwriteCollectEvent(item);
              }}
            />
          ) : (
            <Grid2 container spacing={2}>
              <Grid2 sm={12} md={4}>
                <TextField
                  onChange={(e) => {
                    actions.setCollectEventName(e.target.value);
                  }}
                  value={formData?.collectEventName}
                  fullWidth
                  label="Collection event name"
                />
              </Grid2>
              <Grid2 sm={12} md={4}>
                <DatePicker
                  onChange={(date) => {
                    actions.setStartDate(date);
                    actions.setEndDate(date);
                  }}
                  value={startDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid2>
              <Grid2 sm={12} md={4}>
                <DatePicker
                  onChange={(date) => {
                    actions.setEndDate(date);
                  }}
                  value={endDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid2>
            </Grid2>
          )}
          <Stack>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Stack>
                <InputLabel htmlFor="tiff-file">
                  TIFF image (.zip) - {t("common.seeSensorRequirements")}
                </InputLabel>
                <OutlinedInput
                  id="tiff-file"
                  type="file"
                  inputProps={{
                    ref: fileRef,
                    accept: ".zip",
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFile(e.target.files ? e.target.files[0] : undefined);
                  }}
                />
              </Stack>
              <Stack
                sx={{ ml: "auto" }}
                direction={"row"}
                spacing={2}
                alignItems={"center"}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={!submitEnabled}
                >
                  {t("common.submit")}
                </Button>
                <Tooltip title="Export JSON for Large Image Format">
                  <span>
                    <IconButton
                      disabled={
                        !(
                          formData?.tenantIdOrSlug &&
                          formData?.organizationSlug &&
                          formData?.sensorTypeId
                        )
                      }
                      onClick={async () => {
                        const data = {
                          name:
                            overwrite && overwriteCollectEvent
                              ? overwriteCollectEvent.name
                              : formData?.collectEventName ?? "",
                          sensorTypeId: formData?.sensorTypeId,
                          beginOnUtc:
                            overwrite && overwriteCollectEvent
                              ? overwriteCollectEvent?.beginOnUtc
                              : formData?.beginOnUtc ?? "",
                          endOnUtc:
                            overwrite && overwriteCollectEvent
                              ? overwriteCollectEvent?.endOnUtc
                              : formData?.endOnUtc ?? "",
                        };
                        const begDate = new Date(data.beginOnUtc ?? "");
                        const endDate = new Date(data.endOnUtc ?? "");
                        const json = {
                          tenant: enterprise?.slug,
                          org: formData?.organizationSlug,
                          collectEventName: "",
                          collectEventId: "",
                          userId: userQ.data?.metadata.cai_user_id,
                          email: userQ.data?.email,
                        };
                        const filename = `${enterprise?.slug}-${
                          formData?.organizationSlug
                        }-${`${begDate.toLocaleDateString()}-${endDate.toLocaleDateString()}-${
                          data.name
                        }`}-${sensorType?.slug}.json`
                          .replace(/\s/g, "-")
                          ?.toLowerCase();
                        if (overwrite) {
                          if (overwriteCollectEvent) {
                            json.collectEventId = overwriteCollectEvent.id;
                            json.collectEventName = overwriteCollectEvent.name;
                            downloadFile({
                              data: json,
                              filetype: "text/plain",
                              filename,
                            });
                          }
                        } else if (data) {
                          exportJsonCollectEventMutation.mutateAsync(data, {
                            onSuccess: (res) => {
                              json.collectEventId = res.id;
                              json.collectEventName =
                                formData?.collectEventName ?? "";
                              downloadFile({
                                data: json,
                                filetype: "text/plain",
                                filename,
                              });
                            },
                          });
                        }
                      }}
                    >
                      <Download />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </Box>
            {exportJsonCollectEventMutation.isError ? (
              <Alert
                onClose={() => {
                  exportJsonCollectEventMutation.reset();
                }}
                sx={{ ml: "auto" }}
                severity="error"
              >
                {exportJsonCollectEventMutation.error}
              </Alert>
            ) : null}
          </Stack>
        </form>
        <Link
          sx={{ mt: 2, display: "inline-block" }}
          rel="noreferrer"
          target="_blank"
          href="https://docs.google.com/document/d/1x0W0bioMYFHAikXYsQxgU9qd8xB1gJL2p9LC2Isua2A/edit"
          textTransform={"uppercase"}
        >
          <Tooltip title={t("common.clickHereForMoreInfo")}>
            <Stack direction={"row"} spacing={1}>
              <Typography>{t("common.sensorRequirements")}</Typography>
              <Info />
            </Stack>
          </Tooltip>
        </Link>
      </Paper>
      <Paper sx={{ p: 3, mt: 2, overflow: "auto" }}>
        <Stack>
          {/* <Button
sx={{ ml: "auto" }}
onClick={() => {
  uploadMutation.reset();
  requestRefs.current = [];
  setRequests([]);
}}
>
Reset
</Button> */}
          <RequestTable requests={requests} />
        </Stack>
      </Paper>
    </>
  );
}
